/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Hervey Bay RSL
		Date:		3rd October 2018
		Author:		Edge Marketing Solutions

===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #1a1a1a;
  font-weight: 400;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #c2a059;
}

a:hover {
  color: #ae8125;
}

h1 {
  margin: 0 0 2rem 0;
  font-weight: 400;
  font-size: 2.6rem;
}

h2 {
  margin: 2rem 0;
  font-weight: 400;
  font-size: 2.3rem;
}

h3 {
  margin: 2rem 0;
  font-weight: 400;
  font-size: 2.3rem;
  color: #c2a059;
}

h4 {
  margin: 2rem 0;
  font-weight: 700;
  font-size: 1.6rem;
}

h5 {
  margin: 2rem 0;
  font-weight: 700;
  font-size: 1.6rem;
  color: #c2a059;
}

h6 {
  margin: 2rem 0 0.5rem 0;
  font-weight: 700;
  font-size: 1.3rem;
}

.btn:not([class*="btn-outline-"]) {
  border-radius: 0;
  border: none;
}

.btn-primary {
  background-color: #c2a059;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:hover {
  background-color: #ae8125;
  box-shadow: none;
}

.btn-secondary {
  background-color: #1a1a1a;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background-color: #131313;
  box-shadow: none;
}

.font-featured {
  font-family: "Damion", cursive;
}

h2.font-featured {
  font-size: 4rem;
}

img {
  max-width: 100%;
}

img.img-lightbox {
  cursor: zoom-in;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 2.0rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.7rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1.0rem;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  width: 100%;
  padding: 1.5rem 2rem;
  position: relative;
  z-index: 1000;
  background-color: rgba(26, 26, 26, 0.6);
  color: white;
}

.headernotes {
  position: relative;
  z-index: 1000;
}

.wistia_embed {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  margin-top: -170px;
  min-height: 750px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.video-container:after {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(35, 35, 35, 0.46);
}

.video-text {
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 60%;
  left: 50%;
  z-index: 2000;
  text-align: center;
}

.video-text .title {
  font-family: "Damion", cursive;
  color: #c2a059;
  font-size: 4.3rem;
  line-height: 4.0rem;
}

.video-text .subtitle {
  padding-top: 2rem;
  color: white;
  font-size: 1.4rem;
}

@media (min-width: 1023px) {
  .header {
    background: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1022px) {
  .video-container {
    min-height: 567px;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .video-container {
    min-height: 367px;
    margin-top: 0;
  }
  .video-container .video-text .title {
    font-size: 2.0rem;
    line-height: 2rem;
  }
  .video-container .video-text .subtitle {
    padding-top: 1rem;
    font-size: 0.9rem;
  }
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header {
    background-color: #1a1a1a;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 1rem 0.5rem;
    background-color: #1a1a1a;
    text-align: center;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-dark {
  padding: 0;
}

.navbar-dark .navbar-nav .nav-link {
  padding: 0 1.5rem 0 0;
  color: white;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .active .nav-link {
  color: #c2a059;
}

.navbar-dark .navbar-nav .dropdown-menu {
  border-radius: 0 0.4rem 0.4rem 0.4rem;
}

.navbar-dark .navbar-nav .dropdown-menu .dropdown-item {
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);
}

.navbar-dark .navbar-nav .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.navbar-dark .navbar-nav .dropdown-menu .dropdown-item.active,
.navbar-dark .navbar-nav .dropdown-menu .dropdown-item:active {
  background-color: #c2a059;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-dark {
    padding-top: 2rem;
  }
  .navbar-dark .navbar-nav .nav-link {
    padding: 0 1.5rem 0 0;
    font-size: 0.8rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-dark {
    padding-top: 1rem;
  }
  .navbar-dark .navbar-toggler {
    display: block;
    width: 100%;
    padding: 0.75rem 0;
    border-color: #c2a059;
    border-radius: 0;
    color: white;
    font-size: 1rem;
  }
  .navbar-dark .navbar-nav {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-nav .nav-link {
    padding: 0.7rem;
    font-size: 1rem;
  }
  .navbar-dark .navbar-nav .dropdown-menu {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .navbar-dark .navbar-nav .dropdown-menu .dropdown-item {
    padding: 0.25rem 0.75rem;
    border-bottom: 1px solid rgba(26, 26, 26, 0.1);
    text-align: center;
  }
  .navbar-dark .navbar-nav .dropdown-menu .dropdown-item.active,
  .navbar-dark .navbar-nav .dropdown-menu .dropdown-item:active {
    background-color: #c2a059;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 2rem;
  position: relative;
}

.page .table-bordered {
  border: none;
  border-top: 1px solid #1a1a1a;
}

.page .table-bordered th,
.page .table-bordered td {
  border: none;
}

.page .table-bordered sup {
  top: -0.1rem;
  color: rgba(26, 26, 26, 0.5);
}

.swiper-container {
  width: 100%;
  margin-top: -170px;
}

.swiper-container .swiper-slide {
  position: relative;
}

.swiper-container .swiper-slide img {
  width: 100%;
}

.swiper-container .swiper-slide.with-title:before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 170px);
  background-color: rgba(26, 26, 26, 0.6);
}

.swiper-container .swiper-slide.with-title:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  height: calc(100% - 170px);
  background-image: url(../images/shwoosh.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom right;
}

.swiper-container .swiper-text {
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 60%;
  left: 50%;
  z-index: 2000;
  text-align: center;
}

.swiper-container .swiper-text .title {
  font-family: "Damion", cursive;
  color: #c2a059;
  font-size: 4.3rem;
  line-height: 4.0rem;
}

.swiper-container .swiper-text .subtitle {
  padding-top: 2rem;
  color: white;
  font-size: 1.4rem;
}

.swiper-container .swiper-text .action {
  margin-top: 2rem;
}

.swiper-container .swiper-pagination-bullet {
  background-color: white;
}

.swiper-container .swiper-pagination-bullet-active {
  background-color: #c2a059;
}

.subpage-banner {
  margin-top: -170px;
  z-index: 1;
  position: relative;
}

.subpage-banner img {
  width: 100%;
}

.sidenav h5 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  text-transform: uppercase;
  color: #1a1a1a;
}

.sidenav .nav-link {
  padding: 0.33rem 0rem;
  color: #131313;
}

.sidenav .nav-link:hover {
  color: #c2a059;
}

.sidenav .nav-item.active .nav-link {
  color: #c2a059;
}

.breadcrumb {
  padding: 0 0 1rem 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 0.8rem;
  color: #1a1a1a;
}

.breadcrumb a {
  color: #1a1a1a;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #1a1a1a;
}

.listing {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listing .listing-thumbnail {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
}

.listing .listing-thumbnail img {
  width: 100%;
}

.listing .listing-content {
  position: relative;
  flex: 1 1 auto;
  z-index: 100;
  padding: 1rem;
  margin-top: -3rem;
}

.listing .listing-content .listing-date {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #c2a059;
  font-weight: 700;
  font-size: 1.2rem;
}

.listing .listing-content h3 {
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #1a1a1a;
}

.listing .listing-content h3 a {
  color: #1a1a1a;
}

.listing .listing-content h3 a:hover {
  color: #c2a059;
  text-decoration: none;
}

.listing .listing-content p {
  font-size: 0.9rem;
}

.listing .btn {
  display: inline-block;
}

.listing .listing-action {
  padding: 0 1rem;
}

.panel {
  border: 1px solid rgba(26, 26, 26, 0.6);
}

.panel .panel-content {
  padding: 2rem;
}

.panel .panel-content h6 {
  margin: 0 0 1rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(26, 26, 26, 0.6);
  color: #c2a059;
  font-size: 1rem;
}

.panel .panel-content .btn-back {
  border-radius: 0;
}

.panel .panel-action {
  padding: 2rem;
  border-top: 1px solid rgba(26, 26, 26, 0.6);
  background-color: rgba(26, 26, 26, 0.05);
}

.panel .panel-action .alert {
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.panel .panel-action .alert strong {
  font-size: 0.8rem;
}

.homepage-body {
  display: flex;
}

.homepage-body .content {
  flex-basis: 50%;
  padding: 6rem;
  background-color: #1a1a1a;
  color: white;
}

.homepage-body .content h1 {
  color: #c2a059;
}

.homepage-body .content h1 strong {
  font-family: "Damion", cursive;
  font-weight: normal;
  font-size: 5rem;
}

.homepage-body .image {
  flex-basis: 50%;
  background-size: cover;
}

.swoosh-bg {
  background-image: url(../images/shwoosh.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom right;
}

.hours {
  padding: 3rem 0;
}

.hours .hours-wrapper {
  display: flex;
  justify-content: space-between;
}

.hours .item {
  font-size: 1.4rem;
}

.hours .item strong {
  font-size: 0.9rem;
  color: #c2a059;
}

.hours .item-group:last-of-type {
  display: flex;
  align-items: center !important;
}

.accommodation .section-1 {
  background-color: #016bb7;
}

.accommodation .section-2 {
  background-color: rgba(1, 107, 183, 0.8);
  color: white;
  font-size: 1.4rem;
}

.accommodation .logo-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
}

.accommodation .logo-wrapper .logo {
  align-self: center;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .homepage-body .content {
    padding: 5rem;
  }
  .hours h2 {
    font-size: 2.2rem;
  }
  .hours .item {
    font-size: 1.2rem;
  }
  .hours .item strong {
    font-size: 0.9rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) {
  .show-purchasing {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .show-purchasing .panel {
    display: flex;
  }
  .show-purchasing .panel .panel-content,
  .show-purchasing .panel .panel-action {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .show-purchasing .panel .panel-content .btn-back {
    position: absolute;
    bottom: 0;
  }
  .show-purchasing .panel .panel-action {
    border-left: 1px solid rgba(26, 26, 26, 0.6);
    border-top: none;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .subpage-banner {
    margin-top: 0px;
  }
  .swiper-container {
    margin-top: 0px;
  }
  .swiper-container .swiper-slide.with-title:before {
    height: 100%;
  }
  .swiper-container .swiper-text {
    top: 50%;
  }
  .homepage-body .content {
    padding: 3rem;
    flex-basis: 60%;
  }
  .homepage-body .image {
    flex-basis: 40%;
  }
  .hours {
    padding: 0;
  }
  .hours h2 {
    font-size: 2.2rem;
  }
  .hours .item {
    font-size: 1.0rem;
  }
  .hours .item strong {
    font-size: 0.8rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page {
    width: 100%;
    padding: 2rem 0.5rem;
  }
  .sidenav {
    padding: 2rem;
    background-color: rgba(26, 26, 26, 0.05);
  }
  .subpage-banner {
    margin-top: 0px;
  }
  .swiper-container {
    margin-top: 0px;
  }
  .swiper-container .swiper-slide.with-title:before {
    height: 100%;
  }
  .swiper-container .swiper-text {
    top: 50%;
    width: 90%;
  }
  .swiper-container .swiper-text .title {
    font-size: 2.0rem;
    line-height: 2rem;
  }
  .swiper-container .swiper-text .subtitle {
    padding-top: 1rem;
    font-size: 0.9rem;
  }
  .swiper-container .swiper-text .action {
    margin-top: 1rem;
  }
  .homepage-body {
    display: block;
  }
  .homepage-body .content {
    padding: 1.5rem;
    flex-basis: 100%;
    width: 100%;
  }
  .homepage-body .image {
    width: 100%;
    height: 300px;
    flex-basis: 100%;
  }
  .hours {
    padding: 0;
    text-align: center;
  }
  .hours .hours-wrapper {
    display: block;
  }
  .hours h2 {
    margin: 0 0 2rem 0;
    font-size: 2.4rem;
  }
  .hours .item {
    display: block;
    font-size: 1.2rem;
  }
  .hours .item strong {
    font-size: 1rem;
  }
  .hours .item-group:last-of-type {
    display: block;
  }
  .swoosh-bg {
    background-image: none;
  }
  .accommodation h2 {
    font-size: 2.3rem;
  }
}

/*=========================================================================*/
/* [Event Template Styles] */
.event-overlay-backdrop {
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(26, 26, 26, 0.6);
}

.event-overlay {
  width: 98%;
  position: absolute;
  z-index: 5000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 2.5rem;
  background-color: white;
  border: 1px solid #1a1a1a;
  -webkit-box-shadow: 5px 5px 30px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 30px 5px rgba(0, 0, 0, 0.3);
}

.event-overlay .event-table {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100px;
  margin: 0 0.125rem 1rem;
  background: rgba(26, 26, 26, 0.2);
  cursor: pointer;
}

.event-overlay .event-table .event-table-chair {
  position: absolute;
  border: 1px solid rgba(26, 26, 26, 0.6);
  background: #ffffff;
  width: 30%;
  height: 10px;
  top: 2px;
  left: 0;
}

.event-overlay .event-table .event-table-chair:nth-child(2n) {
  left: auto;
  right: 0;
}

.event-overlay .event-table .event-table-chair.selected {
  background: #c2a059;
  border-color: #ae8125;
}

.event-overlay .event-table .event-table-chair.unavailable {
  background: rgba(26, 26, 26, 0.2);
  color: rgba(26, 26, 26, 0.2);
  border: none;
}

.event-overlay .event-chair-selector {
  display: flex;
  flex-wrap: wrap;
}

.event-overlay .event-chair-selector .event-chair {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 1rem 1rem calc(35% - 50px);
  border: 1px solid rgba(26, 26, 26, 0.6);
  cursor: pointer;
}

.event-overlay .event-chair-selector .event-chair:nth-child(2n) {
  margin: 0 calc(35% - 50px) 1rem 1rem;
}

.event-overlay .event-chair-selector .event-chair.selected {
  background: #c2a059;
  border-color: #ae8125;
}

.event-overlay .event-chair-selector .event-chair.unavailable {
  background: rgba(26, 26, 26, 0.2);
  color: rgba(26, 26, 26, 0.2);
  border: none;
  cursor: not-allowed;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 2.5rem 2rem;
  background-color: #1a1a1a;
  font-size: 0.8rem;
  color: white;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: #c2a059;
}

.footer h6 {
  margin: 0 0 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #c2a059;
}

.footer h6 a {
  color: #c2a059;
}

.footer .nav {
  margin-bottom: 1rem;
}

.footer .nav-link {
  padding: 0.3rem 0;
}

.footnotes,
.headernotes {
  padding: 0.5rem 2rem;
  background-color: #131313;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
}

.footnotes a,
.headernotes a {
  color: rgba(255, 255, 255, 0.5);
}

.footnotes span,
.headernotes span {
  display: inline-block;
  padding: 0 1rem;
}

.footnotes span:first-child,
.headernotes span:first-child {
  padding-left: 0;
}

.footnotes span:last-child,
.headernotes span:last-child {
  padding-right: 0;
}

.footnotes .fa-facebook-square,
.headernotes .fa-facebook-square {
  color: #c2a059;
  font-size: 1rem;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footnotes span,
  .headernotes span {
    display: block;
    padding: 0.3rem 1rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footnotes span,
  .headernotes span {
    display: block;
    padding: 0.3rem 0rem;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
