/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Hervey Bay RSL
		Date:		3rd October 2018
		Author:		Edge Marketing Solutions

===========================================================================*/

	$color-black: rgb(19, 19, 19);
	$color-black-med: rgb(26, 26, 26);
	$color-gold: rgb(194, 160, 89);
	$color-gold-highlight: rgb(174, 129, 37);
	$color-white: rgb(255, 255, 255);

	$font-default: 'Open Sans', sans-serif;
	$font-featured: 'Damion', cursive;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-black-med;
		font-weight: 400;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-gold;
	}

		a:hover
		{
			color: $color-gold-highlight;
		}

	h1
	{
		margin: 0 0 2rem 0;

		font-weight: 400;
		font-size: 2.6rem;
	}

	h2
	{
		margin: 2rem 0;

		font-weight: 400;
		font-size: 2.3rem;
	}

	h3
	{
		margin: 2rem 0;

		font-weight: 400;
		font-size: 2.3rem;
		color: $color-gold
	}

	h4
	{
		margin: 2rem 0;

		font-weight: 700;
		font-size: 1.6rem;
	}

	h5
	{
		margin: 2rem 0;

		font-weight: 700;
		font-size: 1.6rem;
		color: $color-gold
	}

	h6
	{
		margin: 2rem 0 0.5rem 0;

		font-weight: 700;
		font-size: 1.3rem;
	}

	.btn:not([class*="btn-outline-"])
	{
		border-radius: 0;
		border: none;
	}

		.btn-primary
		{
			background-color: $color-gold;
		}

		.btn-primary:not(:disabled):not(.disabled):active:focus,
		.btn-primary:not(:disabled):not(.disabled).active:focus,
		.show > .btn-primary.dropdown-toggle:focus,
		.btn-primary:hover
		{
			background-color: $color-gold-highlight;

			box-shadow: none;
		}

		.btn-secondary
		{
			background-color: $color-black-med;
		}

		.btn-secondary:not(:disabled):not(.disabled):active:focus,
		.btn-secondary:not(:disabled):not(.disabled).active:focus,
		.show > .btn-secondary.dropdown-toggle:focus
		{
			background-color: $color-black;

			box-shadow: none;
		}

	.font-featured
	{
		font-family: $font-featured;
	}

		h2.font-featured
		{
			font-size: 4rem;
		}

	img
	{
		max-width: 100%;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		h1
		{
			font-size: 2.0rem;
		}

		h2
		{
			font-size: 1.7rem;
		}

		h3
		{
			font-size: 1.7rem;
		}

		h4
		{
			font-size: 1.2rem;
		}

		h5
		{
			font-size: 1.2rem;
		}

		h6
		{
			font-size: 1.0rem;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		width: 100%;
		padding: 1.5rem 2rem;
		position: relative;
		z-index: 1000;

		background-color: transparentize($color-black-med, 0.4);

		color: $color-white;
	}

	.headernotes
	{
		position: relative;
		z-index: 1000;
	}

	.wistia_embed {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.video-container {
	margin-top: -170px;
	min-height: 750px;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	}

	.video-container:after {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	background-color: rgba(35, 35, 35, 0.46);
	}

	.video-text {
	position: absolute;
	top: 55%;
	transform: translate(-50%, -50%);
	width: 60%;
	left: 50%;
	z-index: 2000;
	text-align: center;
	}

	.video-text .title {
	font-family: "Damion", cursive;
	color: #c2a059;
	font-size: 4.3rem;
	line-height: 4.0rem;
	}

	.video-text .subtitle {
	padding-top: 2rem;
	color: white;
	font-size: 1.4rem;
	}



	@media (min-width: 1023px) {
	.header {
			background: none!important;
	}
	}

	@media (min-width: 768px) and (max-width: 1022px) {
			.video-container {
					min-height: 567px;
					margin-top: 0;
			}
	}

	@media (max-width: 768px) {
			.video-container {
					min-height: 367px;
					margin-top: 0;
			}

			.video-container .video-text .title {
			font-size: 2.0rem;
			line-height: 2rem;
			}

			.video-container .video-text .subtitle {
			padding-top: 1rem;
			font-size: 0.9rem;
			}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			background-color: $color-black-med;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header
		{
			padding: 1rem 0.5rem;

			background-color: $color-black-med;

			text-align: center;
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-dark
	{
		padding: 0;

		.navbar-nav
		{
			.nav-link
			{
				padding: 0 1.5rem 0 0;

				color: $color-white;
				text-transform: uppercase;
				font-size: 0.9rem;
			}

			.nav-link:hover,
			.nav-link:focus,
			.active .nav-link
			{
				color: $color-gold;
			}

			.dropdown-menu
			{
				border-radius: 0 0.4rem 0.4rem 0.4rem;

				.dropdown-item
				{
					padding: 0.25rem 0.75rem;

					border-bottom: 1px solid transparentize($color-black-med, 0.90);
				}

				.dropdown-item:last-child
				{
					border-bottom: none;
				}

				.dropdown-item.active,
				.dropdown-item:active
				{
					background-color: $color-gold;
				}
			}
		}
	}


	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-dark
		{
			padding-top: 2rem;

			.navbar-nav
			{
				.nav-link
				{
					padding: 0 1.5rem 0 0;
					font-size: 0.8rem;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-dark
		{
			padding-top: 1rem;

			.navbar-toggler
			{
				display: block;
				width: 100%;
				padding: 0.75rem 0;

				border-color: $color-gold;
				border-radius: 0;

				color: $color-white;
				font-size: 1rem;
			}

			.navbar-nav
			{
				background-color: transparentize($color-white, 0.9);

				.nav-link
				{
					padding: 0.7rem;
					font-size: 1rem;
				}

				.dropdown-menu
				{
					border-radius: 0;
					background-color: transparentize($color-white, 0.2);

					.dropdown-item
					{
						padding: 0.25rem 0.75rem;

						border-bottom: 1px solid transparentize($color-black-med, 0.90);
						text-align: center;
					}

					.dropdown-item.active,
					.dropdown-item:active
					{
						background-color: $color-gold;
					}
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 2rem;
		position: relative;

		.table-bordered
		{
			border: none;
			border-top: 1px solid $color-black-med;

			th,
			td
			{
				border: none;
			}

			sup
			{
				top: -0.1rem;

				color: transparentize($color-black-med, 0.5);
			}
		}
	}

	.swiper-container
	{
		width: 100%;

		margin-top: -170px;

		.swiper-slide
		{
			position: relative;

			img
			{
				width: 100%;
			}
		}

		.swiper-slide.with-title:before
		{
			content: ' ';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: calc(100% - 170px);

			background-color: transparentize($color-black-med, 0.4);
		}

		.swiper-slide.with-title:after
		{
			content: ' ';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			z-index: 1000;
			height: calc(100% - 170px);

			background-image: url(../images/shwoosh.png);
			background-repeat: no-repeat;
			background-size: auto 100%;
			background-position: bottom right;
		}

		.swiper-text
		{
			position: absolute;
			top: 55%;
			transform: translate(-50%, -50%);
			width: 60%;
			left: 50%;
			z-index: 2000;

			text-align: center;

			.title
			{
				font-family: $font-featured;
				color: $color-gold;
				font-size: 4.3rem;
				line-height: 4.0rem;
			}

			.subtitle
			{
				padding-top: 2rem;

				color: $color-white;
				font-size: 1.4rem;
			}

			.action
			{
				margin-top: 2rem;
			}
		}

		.swiper-pagination-bullet
		{
			background-color: $color-white;
		}

		.swiper-pagination-bullet-active
		{
			background-color: $color-gold;
		}
	}

	.subpage-banner
	{
		margin-top: -170px;
		z-index: 1;
		position: relative;

		img
		{
			width: 100%;
		}
	}

	.sidenav
	{
		h5
		{
			margin: 0 0 0.5rem 0;
			font-size: 1rem;
			text-transform: uppercase;
			color: $color-black-med;
		}

		.nav-link
		{
			padding: 0.33rem 0rem;

			color: $color-black;
		}

			.nav-link:hover
			{
				color: $color-gold;
			}

		.nav-item.active .nav-link
		{
			color: $color-gold;
		}
	}

	.breadcrumb
	{
		padding: 0 0 1rem 0;

		border-radius: 0;
		background-color: transparent;

		font-size: 0.8rem;
		color: $color-black-med;

		a
		{
			color: $color-black-med;
		}

		.breadcrumb-item + .breadcrumb-item::before
		{
			color: $color-black-med;
		}
	}

	.listing
	{
		padding: 0.5rem;
		display: flex;
		flex-direction: column;
		width: 100%;

		.listing-thumbnail
		{
			display: block;
			position: relative;
			z-index: 1;
			width: 100%;

			img
			{
				width: 100%;
			}
		}

		.listing-content
		{
			position: relative;
			flex: 1 1 auto;
			z-index: 100;
			padding: 1rem;
			margin-top: -3rem;

			.listing-date
			{
				display: inline-block;
				padding: 0.5rem 1rem;

				background-color: $color-gold;

				font-weight: 700;
				font-size: 1.2rem;
			}

			h3
			{
				margin: 1rem 0;

				font-size: 1.2rem;
				font-weight: 700;
				color: $color-black-med;

				a
				{
					color: $color-black-med;
				}

				a:hover
				{
					color: $color-gold;
					text-decoration: none;
				}
			}

			p
			{
				font-size: 0.9rem;
			}
		}

		.btn
		{
			display: inline-block;
		}

		.listing-action
		{
			padding: 0 1rem;
		}
	}

	.panel
	{
		border: 1px solid transparentize($color-black-med, 0.4);

		.panel-content
		{
			padding: 2rem;

			h6
			{
				margin: 0 0 1rem 0;
				padding-bottom: 0.5rem;

				border-bottom: 1px solid transparentize($color-black-med, 0.4);

				color: $color-gold;
				font-size: 1rem;
			}


			.btn-back
			{
				border-radius: 0;
			}
		}

		.panel-action
		{
			padding: 2rem;

			border-top: 1px solid transparentize($color-black-med, 0.4);
			background-color: transparentize($color-black-med, 0.95);

			.alert
			{
				font-size: 0.9rem;
				line-height: 0.9rem;

				strong
				{
					font-size: 0.8rem;
				}
			}
		}

	}

	.homepage-body
	{
		display: flex;

		.content
		{
			flex-basis: 50%;
			padding: 6rem;

			background-color: $color-black-med;

			color: $color-white;

			h1
			{
				color: $color-gold;

				strong
				{
					font-family: $font-featured;
					font-weight: normal;
					font-size: 5rem;
				}
			}
		}

		.image
		{
			flex-basis: 50%;

			background-size: cover;
		}
	}

	.swoosh-bg
	{
		background-image: url(../images/shwoosh.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: bottom right;
	}

	.hours
	{
		padding: 3rem 0;

		.hours-wrapper
		{
			display: flex;
			justify-content: space-between;
		}

		.item
		{
			font-size: 1.4rem;

			strong
			{
				font-size: 0.9rem;
				color: $color-gold;
			}
		}

		.item-group:last-of-type {
			display: flex;
			align-items: center !important;
		}
	}

	.accommodation
	{
		.section-1
		{
			background-color: rgb(1, 107, 183);
		}

		.section-2
		{
			background-color: rgba(1, 107, 183, 0.8);

			color: $color-white;
			font-size: 1.4rem;
		}

		.logo-wrapper
		{
			display: flex;
			height: 100%;
			justify-content: center;

			.logo
			{
				align-self: center;
			}
		}

	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.homepage-body
		{
			.content
			{
				padding: 5rem;
			}
		}

		.hours
		{
			h2
			{
				font-size: 2.2rem;
			}

			.item
			{
				font-size: 1.2rem;

				strong
				{
					font-size: 0.9rem;
				}
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) {
		.show-purchasing {
			flex: 0 0 100%;
			max-width: 100%;

			.panel
			{
				display: flex;

				.panel-content,
				.panel-action
				{
					flex: 0 0 50%;
					max-width: 50%;
				}

				.panel-content
				{
					.btn-back
					{
						position: absolute;
						bottom: 0;
					}
				}

				.panel-action
				{
					border-left: 1px solid transparentize($color-black-med, 0.4);
					border-top: none;
				}

			}
		}

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.subpage-banner
		{
			margin-top: 0px;
		}

		.swiper-container
		{
			margin-top: 0px;

			.swiper-slide.with-title:before
			{
				height: 100%;
			}

			.swiper-text
			{
				top: 50%;
			}
		}

		.homepage-body
		{
			.content
			{
				padding: 3rem;
				flex-basis: 60%;
			}

			.image
			{
				flex-basis: 40%;
			}
		}

		.hours
		{
			padding: 0;

			h2
			{
				font-size: 2.2rem;
			}

			.item
			{
				font-size: 1.0rem;

				strong
				{
					font-size: 0.8rem;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page
		{
			width: 100%;
			padding: 2rem 0.5rem;
		}

		.sidenav
		{
			padding: 2rem;

			background-color: transparentize($color-black-med, 0.95);
		}

		.subpage-banner
		{
			margin-top: 0px;
		}

		.swiper-container
		{
			margin-top: 0px;

			.swiper-slide.with-title:before
			{
				height: 100%;
			}

			.swiper-text
			{
				top: 50%;
				width: 90%;

				.title
				{
					font-size: 2.0rem;
					line-height: 2rem;
				}

				.subtitle
				{
					padding-top: 1rem;
					font-size: 0.9rem;
				}

				.action
				{
					margin-top: 1rem;
				}
			}
		}

		.homepage-body
		{
			display: block;
			.content
			{
				padding: 1.5rem;
				flex-basis: 100%;
				width: 100%;
			}

			.image
			{
				width: 100%;
				height: 300px;
				flex-basis: 100%;
			}
		}

		.hours
		{
			padding: 0;

			text-align: center;

			.hours-wrapper
			{
				display: block;
			}

			h2
			{
				margin: 0 0 2rem 0;
				font-size: 2.4rem;
			}

			.item
			{
				display: block;

				font-size: 1.2rem;

				strong
				{
					font-size: 1rem;
				}
			}

			.item-group:last-of-type {
				display: block;
			}
		}

		.swoosh-bg
		{
			background-image: none;
		}

		.accommodation
		{
			h2
			{
				font-size: 2.3rem;
			}
		}
	}

	/*=========================================================================*/

	/* [Event Template Styles] */

	.event-overlay-backdrop
	{
		position: fixed;
		z-index: 4000;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: transparentize($color-black-med, 0.4);
	}

	.event-overlay
	{
		width: 98%;
		position: absolute;
		z-index: 5000;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 2.5rem;

		background-color: $color-white;
		border: 1px solid $color-black-med;
		-webkit-box-shadow: 5px 5px 30px 5px rgba(0,0,0,0.3);
		box-shadow: 5px 5px 30px 5px rgba(0,0,0,0.3);

		.event-table {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			width: 50px;
			height: 100px;
			margin: 0 0.125rem 1rem;

			background: transparentize($color-black-med, 0.8);
			//color: #ffffff;
			cursor: pointer;

			.event-table-chair {
				position: absolute;
				border: 1px solid transparentize($color-black-med, 0.4);
				background: #ffffff;
				width: 30%;
				height: 10px;
				top: 2px;
				left: 0;

				&:nth-child(2n) {
					left: auto;
					right: 0;
				}

				&.selected {
					background: $color-gold;
					border-color: $color-gold-highlight;
				}

				&.unavailable {
					background: transparentize($color-black-med, 0.8);
					color: transparentize($color-black-med, 0.8);
					border: none;
				}
			}
		}

		.event-chair-selector {
			display: flex;
			flex-wrap: wrap;

			.event-chair {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 50px;
				height: 50px;
				margin: 0 1rem 1rem calc(35% - 50px);

				border: 1px solid transparentize($color-black-med, 0.4);
				cursor: pointer;

				&:nth-child(2n) {
					margin: 0 calc(35% - 50px) 1rem 1rem;
				}

				&.selected {
					background: $color-gold;
					border-color: $color-gold-highlight;
				}

				&.unavailable {
					background: transparentize($color-black-med, 0.8);
					color: transparentize($color-black-med, 0.8);
					border: none;
					cursor: not-allowed;
				}
			}
		}
	}



	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		padding: 2.5rem 2rem;

		background-color: $color-black-med;

		font-size: 0.8rem;
		color: $color-white;

		a
		{
			color: $color-white;
		}

			a:hover
			{
				color: $color-gold;
			}

		h6
		{
			margin: 0 0 0.5rem;

			font-size: 0.9rem;
			text-transform: uppercase;
			color: $color-gold;

			a
			{
				color: $color-gold;
			}
		}

		.nav
		{
			margin-bottom: 1rem;
		}

		.nav-link
		{
			padding: 0.3rem 0;
		}
	}

	.footnotes,
	.headernotes
	{
		padding: 0.5rem 2rem;
		background-color: $color-black;

		color: transparentize($color-white, 0.5);
		font-size: 0.8rem;

		a
		{
			color: transparentize($color-white, 0.5);
		}

		span
		{
			display: inline-block;
			padding: 0 1rem;
		}

			span:first-child
			{
				padding-left: 0;
			}

			span:last-child
			{
				padding-right: 0;
			}

		.fa-facebook-square
		{
			color: $color-gold;
			font-size: 1rem;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.footnotes,
		.headernotes
		{
			span
			{
				display: block;
				padding: 0.3rem 1rem;
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			text-align: center;
		}

		.footnotes,
		.headernotes
		{
			span
			{
				display: block;
				padding: 0.3rem 0rem;
			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}
